import * as React from 'react'
import { Link } from 'gatsby'
//import { StaticImage } from "gatsby-plugin-image"

import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'

import KoneLogo from '/src/images/samarbete/kone.svg'
//import ParakeyLogo from '/src/images/samarbete/parakey.svg'

const SamarbetePage = () => {
	return (
		<Layout pageTitle="Samarbetspartners" showBox={false}>
			
			<div className="pageRows">

				<div style={{textAlign: 'center'}}>
					<div style={{display: 'inline-block'}}>
						<div>
							<h1 className="center" style={{fontSize: '58px'}}>Bättre tillsammans</h1>
							<p style={{fontSize: '22px', marginTop: '25px'}}>Genom samarbete med moderna framåtlutade företag blir vardagen ännu bättre.</p>
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className="rowIcon">
							<img src={KoneLogo} alt="" />
						</div>
						<div className="rowText">
							<h2>Smarta hissar från Kone</h2>
							<p>Med hjälp av smarta uppkopplade hissar från Kone kan boende enkelt i Brfify-appen se hissarnas status och vid problem övergripande information om ärende, exempelvis om tekniker från Kone är på väg, på plats eller om ärendet är avslutat. Boende kan även få notis vid förändrad hiss-status så slipper de manuellt att titta i appen.</p>

							<p>Dessutom kan boende åka hiss via Brfify-appen! Med en enda knapptryckning kommer hissen från och till önskat våningsplan, exempelvis direkt från entréplan till lägenhetens våningsplan.</p>

							<p>
								<Link to="/samarbete/kone" className="inlineButton">Läs mer</Link>
							</p>
						</div>
					</div>
				</div>

				{ /* 
				<div>
					<div>
						<div className="rowIcon">
							<img src={ParakeyLogo} alt="" />
						</div>
						<div className="rowText">
							<h2>Smarta lås från Parakey</h2>
							<p>Med hjälp av smarta lås och passagesystem från Parakey kan boende enkelt i Brfify-appen hantera sina egna digitala nycklar, exempelvis till entréer, parkeringar och så vidare. Brfify kan även automatiskt tilldela tillfälliga digitala nycklar till bokade objekt, såsom exempelvis tvättstugan, övernattningslägenheten eller bastun.</p>

							<p>Enkelt och smidigt för alla! Boende slipper vänta på hjälp från styrelse/förvaltare vilket istället kan lägga tiden på annat.</p>

							<p>
								<Link to="/samarbete/parakey" className="inlineButton">Läs mer</Link>
							</p>
						</div>
					</div>
				</div>
				
				<div>
					<div>
						<div className="rowIcon">
							XXX
						</div>
						<div className="rowText">
							<h2>XXX</h2>
							<p>XXX</p>
						</div>
					</div>
				</div>
				
				<div>
					<div>
						<div className="rowIcon">
							XXX
						</div>
						<div className="rowText">
							<h2>XXX</h2>
							<p>XXX</p>
						</div>
					</div>
				</div>
				
				<div>
					<div>
						<div className="rowIcon">
							XXX
						</div>
						<div className="rowText">
							<h2>XXX</h2>
							<p>XXX</p>
						</div>
					</div>
				</div>
				*/}
				
				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faScrewdriverWrench} />
						</div>
						<div className="rowText">
							<h2>Förvaltare?</h2>
							<p>Är du en ekonomisk- och/eller teknisk förvaltare som vill erbjuda Brfify-plattformen till dina kunder? Varmt välkommen att kontakta <a href="mailto:hej@brfify.se">hej@brfify.se</a> för att höra mer kring Brfifys Partner-program! Samt hur Brfify kan kopplas samman med era system så att exempelvis boende ser avier direkt i appen och ni får ärenden direkt in i ert ärendehanteringssystem.</p>
						</div>
					</div>
				</div>
				
				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faHeart} />
						</div>
						<div className="rowText">
							<h2>Samarbeta?</h2>
							<p>Vill du och din organisation tillsammans med Brfify erbjuda bostadsrättsföreningar och fastighetsägare en enklare och smidigare vardag? Varmt välkommen att kontakta <a href="mailto:hej@brfify.se">hej@brfify.se</a> för att diskutera samarbete!</p>
						</div>
					</div>
				</div>

			</div>

		</Layout>
	)
}

export default SamarbetePage